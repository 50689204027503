.loading-container {
    width: 100%;
    max-width: 500px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .loading-text {
    font-size: 1.5rem;
    color: #333;
  }
  

  