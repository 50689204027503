.final-message-container {
    width: 100%;
    max-width: 500px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .final-message-heading {
    background-color: navy;
    color: white;
    padding: 15px;
    border-radius: 5px;
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .final-message-body {
    margin-bottom: 20px;
  }
  
  .final-message-text {
    font-size: 1.25rem;
    color: #333;
  }
  
  .final-message-subtext {
    font-size: 0.875rem;
    color: #666;
  }
  
  .final-message-close {
    background-color: white;
    border: 1px solid grey;
    color: grey;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
  }