.drag-and-drop-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.drop-zone {
  width: 400px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  background-color: #ffffff;
  position: relative;
  padding: 20px;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  transition: all 0.3s ease;
}


.sub-text {
  font-size: 12px;
  color: #999999;
  margin-bottom: 36px;
  margin-top: 20px;
  text-align: center;
}

.drop-text {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  text-align: center;
}

.or-text {
  margin: 10px 0;
  font-size: 14px;
  color: #999999;
}

.upload-button {
  background-color: #f36528;
  color: #ffffff;
  padding: 10px 35px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.upload-button:hover {
  background-color: #d85421;
}

.upload-icon {
  background-color: #ffffff;
  color: #f36528;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 16px;
  padding-bottom: 3px;
  width: 18px;
  height: 18px;
  margin-right: 8px;
  font-weight: bold;
}

.upload-button:focus {
  outline: none;
}

.supported-files-text {
  font-size: 16px;
  color: #000000;
  text-align: center;
  line-height: 1.2;
  margin-top: 22px;
}

.image-preview {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
  gap: 10px;
  margin-top: 10px;
}

.preview-image-container, .add-image-container {
  width: 80px;
  height: 80px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background-color: #f8f8f8;
  overflow: hidden;
}

.add-image-container{
  border: 1px solid black;
  border-radius: 0px;
}

.add-image-container {
  cursor: pointer;
}

.add-image-icon {
  color: #f36528;
  font-size: 24px;
  font-weight: bold;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remove-image {
  position: absolute;
  top: 5px;
  right: 5px;
  padding-bottom: 6px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .drop-zone {
    width: 350px;
    height: 350px;
  }
  .image-preview {
    margin-top: 0px;
  }

  .remove-image{
    top: 3px;
    right: 3px;
    width: 20px;
    height: 20px;
    font-size: 20px;
    padding-bottom: 2px;
  }

  .upload-button {
    padding: 10px 20px;
  }
  
  .sub-text{
    margin-bottom: 10px;
  }

  .drop-text {
    font-size: 14px;
  }

  .or-text {
    font-size: 12px;
  }

  .supported-files-text {
    font-size: 10px;
  }

  .preview-image-container, .add-image-container {
    width: 70px;
    height: 70px;
  }
}

@media (max-width: 576px) {
  .drop-zone {
    width: 300px;
    height: 300px;
  }

  .image-preview {
    margin-top: 0px;
  }

  .sub-text{
    margin-bottom: 10px;
  }

  .remove-image{
    top: 3px;
    right: 3px;
    width: 20px;
    height: 20px;
    font-size: 20px;
    padding-bottom: 2px;
  }

  .upload-button {
    padding: 8px 16px;
  }

  .drop-text {
    font-size: 12px;
  }

  .or-text {
    font-size: 10px;
  }

  .supported-files-text {
    font-size: 8px;
  }

  .preview-image-container, .add-image-container {
    width: 60px;
    height: 60px;
  }
}

