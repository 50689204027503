.generate-form {
    width: 100%;
    max-width: 400px;  // Adjust width if needed
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .generate-form h1 {
    background-color: navy;
    color: white;
    padding: 15px;
    border-radius: 5px;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 20px;  // Space below the heading
  }
  
  .generate-form input[type="text"],
  .generate-form input[type="email"],
  .generate-form textarea {
    width: 100%;
    margin-top: 15px;  // Space below each input field
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .generate-form textarea {
    height: 100px;  // Adjust height for the textarea
  }
  
  .generate-form .radio-group,
  .generate-form .checkbox-group {
    margin-top: 20px;  // Increased space below radio and checkbox groups
  }
  
  .generate-form .radio-group label,
  .generate-form .checkbox-group label {
    display: flex;
    align-items: center;
    margin-top: 10px; // Increased space between each radio/checkbox option
  }
  
  .generate-form .radio-group input,
  .generate-form .checkbox-group input {
    margin-right: 15px;  // Increased space between radio/checkbox and label text
  }
  
  .generate-form .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
  
  .generate-form .button-group button {
    width: 48%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .generate-form .button-group .cancel-button {
    background-color: white;
    border: 1px solid grey;
    color: grey;
    width: 30%;
  }
  
  .generate-form .button-group .request-button {
    background-color: #f36528;
    color: white;
    width: 50%;
  }

  .error-text {
    color: red;
    font-size: 0.875rem;
    margin-top: 0px;
  }
  
  
  
  @media (max-width: 992px) {
    .generate-form {
      padding: 15px;
    }
  
    .generate-form h1 {
      font-size: 1.25rem;
    }
  
    .generate-form .button-group button {
      width: 48%;
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 768px) {
    .generate-form {
      padding: 10px;
    }
  
    .generate-form h1 {
      font-size: 1.1rem;
    }
  
    .generate-form .button-group button {
      width: 48%;
      font-size: 0.8rem;
    }
  }
  
  @media (max-width: 576px) {
    .generate-form {
      padding: 5px;
    }
  
    .generate-form h1 {
      font-size: 1rem;
    }
  
    .generate-form .button-group button {
      width: 100%;
      font-size: 0.75rem;
    }


  }


.cursor {
  cursor: pointer;
  user-select: none;
}


.cancel-button:hover {
  background-color: #dedede;
}


.request-button:hover {
  background-color: #d85421;
}