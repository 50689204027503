.generate-button {
    margin-top: 30px;
    width: 80% !important;  // Make button full width
    border: none;
    background-color: #f36528;
    color: white;
    font-size: 1.7rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }

.generate-button:hover{
  background-color: #d85421;
}

  .generate-button.disabled {
    background-color: #d6d6d6;
    cursor: not-allowed;

    .button-icon{
      fill: #d6d6d6;
      stroke: #d6d6d6;
    }
  }

.generate-button.disabled:hover{
  background-color: #d6d6d6;
}


  @media (max-width: 992px) {
    .generate-button {
      width: 350px !important;
      font-size: 1.5rem;
    }
    .button-icon{
     width: 90px;
     height: 90px;
    }
  }
  
  @media (max-width: 768px) {
    .generate-button {
      width: 300px !important;
      font-size: 1.5rem;
    }
    .button-icon{
      width: 80px;
      height: 80px;
     }
  }
  
  @media (max-width: 576px) {
    .generate-button {
      width: 250px !important;
      font-size: 1.1rem;
    }
    .button-icon{
      width: 70px;
      height: 70px;
     }
  }

